/* globals jQuery */
($ => {
  // slick index
  $(function () {
    const mql = window.matchMedia('screen and (max-width: 768px)');
    const checkBreakPoint = (mql) => {
      if (mql.matches) {
        $('.js-slick.slick-initialized').slick('unslick');
      } else {
        $('.js-slick').not('.slick-initialized').slick({
          infinite: true,
          autoplay: true,
          // autoplaySpeed: 6000,
          speed: 500,
          variableWidth: true,
          centerMode: true,
          centerPadding: "0px",
          arrows: false,
          dots: false,
          slidesToShow: 1,
          initialSlide: len,
          swipe: false,
          waitForAnimate: true
        }).on('afterChange', (event, slick, currentSlide) => {
          if (currentSlide === len * 3) {
            setTimeout(() => {
              slick.slickGoTo(len, true);
            }, 0);
          }
        });
      }
    };
    const len = $('.js-slick').children().length;
    const $children = $('.js-slick').children();

    $children.clone(true, true)
      .add($children.clone(true, true))
      .add($children.clone(true, true))
      .addClass('only-tab-min')
      .appendTo('.js-slick');


    mql.addListener(checkBreakPoint);
    checkBreakPoint(mql);
  });
  // slick
  $(function () {
    $('.slick-slider').each((idx, slider) => {
      const $slider = $(slider);
      if ($(slider).find('.slick-slide').length <= 1) {
        $slider.slick('unslick');
      }
    });
  });
  // searchForm
  $(function () {
    const $body = $('body');
    const $input = $('.searchForm__input');
    $(document).on({
      'click': () => {
        $body.removeClass('is-searchActive');
      }
    }).on({
      'click': event => {
        event.stopPropagation();
      }
    }, '.searchForm').on({
      'click': event => {
        event.stopPropagation();
        $body.toggleClass('is-searchActive');
      }
    }, '.searchForm__button.-tab').on({
      'submit': event => {
        const val = $input.val().replace(/^s+|s+$/g, '');
        if (val === '') {
          event.preventDefault();
        }
      }
    }, '.searchForm form');
  });
})(jQuery);